import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IIntegrationsState } from '../../redux/integrations/integrations.types';
import { updateSmartmovingOpportunityAccountManager } from '../../redux/integrations/integrations.redux';
import { IAuthUserState } from '../../redux/authUser/authUser.types';
import { UserRole } from '../../redux/users/users.types';

interface IAccountManagerSelectProps {
  defaultValue?: string;
  disabled?: boolean;
  opportunityId: number | string;
}

export default function AccountManagerSelect({ defaultValue, disabled, opportunityId }: IAccountManagerSelectProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { aircallUsers } = useSelector((state: { integrations: IIntegrationsState }) => state.integrations);
  const { user } = useSelector((state: { authUser: IAuthUserState }) => state.authUser);
  const allowEdit = user.role !== UserRole.default;

  const handleChange = (value: string) => {
    dispatch(updateSmartmovingOpportunityAccountManager({ id: opportunityId as string, accountManager: value! }));
  };

  const options = aircallUsers.map(user => ({
    label: `${user.name}, ${user.email}`,
    value: user.name,
  }));

  return (
    <div onClick={e => e.stopPropagation()}>
      {allowEdit ? (
        <Select
          style={{ width: '100%' }}
          defaultValue={defaultValue}
          placeholder={t('smartmovingOpportunity.selectAccountManagerTitle')}
          options={options}
          allowClear
          disabled={disabled}
          onChange={handleChange}
        />
      ) : (
        defaultValue
      )}
    </div>
  );
}
