import { all } from 'redux-saga/effects';
import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing.s5};
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: 'questions-count chat-open calls ' 'call-directions call-handlers call-handlers' 'calls-departments calls-departments calls-departments' 'call-reasons call-reasons call-reasons' 'top-questions top-questions top-questions';
  gap: 2rem;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'questions-count chat-open' 'calls calls' 'call-directions call-directions' 'call-handlers call-handlers' 'calls-departments calls-departments' 'call-reasons call-reasons' 'top-questions top-questions';
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-areas: 'questions-count' 'chat-open' 'calls' 'call-directions' 'call-handlers' 'calls-departments' 'call-reasons' 'top-questions';
  }
`;

export const CardText = styled.div`
  margin-top: ${({ theme }) => theme.spacing.s6};
  padding-left: ${({ theme }) => theme.spacing.s2};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.s2};
`;

export const QuestionsCountWrapper = styled.div`
  grid-area: questions-count;
`;

export const TopQuestionsCountWrapper = styled.div`
  grid-area: top-questions;
`;

export const ChatOpenWrapper = styled.div`
  grid-area: chat-open;
`;

export const CallsSuccessWrapper = styled.div`
  grid-area: calls;
`;

export const CallsDepartmentWrapper = styled.div`
  grid-area: calls-departments;
`;

export const CallsDirectionsWrapper = styled.div`
  grid-area: call-directions;
`;

export const CallsHandlersWrapper = styled.div`
  grid-area: call-handlers;
`;

export const CallReasonsWrapper = styled.div`
  grid-area: call-reasons;
`;

export const TopQuestions = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.s3};
`;

export const CardRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.s3};
`;

export const TopQuestion = styled.div<{ $width: number }>`
  position: relative;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.s4};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.s3};
  z-index: 0;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: ${({ $width }) => $width}%;

    background: ${({ theme }) => theme.colors.bg.dark};
    border-radius: ${({ theme }) => theme.radius.xl};
    z-index: -1;
  }
`;
