import { useSelector } from 'react-redux';
import { IUserWithId, IUsersState } from '../../redux/users/users.types';
import * as SC from './styles';
import { useTranslation } from 'react-i18next';
import { IAuthUserState } from '../../redux/authUser/authUser.types';
import Avatar from '../Avatar/Avatar';
import { Typography } from '../../components/Typography/Typography';
import SearchInput from '../SearchInput/SearchInput';
import Button from '../Button/Button';
import IconButton from '../IconButton/IconButton';
import Card from '../Card/Card';
import Loader, { LoaderEnum } from '../Loader/Loader';

interface IUserList {
  onEditUser: (id?: string) => void;
  onDeleteUser: (id: string) => void;
}

export default function UserList({ onEditUser, onDeleteUser }: IUserList) {
  const { t } = useTranslation();
  const { users, isLoadingUsers } = useSelector((state: { users: IUsersState }) => state.users);
  const { user: authUser } = useSelector((state: { authUser: IAuthUserState }) => state.authUser);

  function getUser(user: IUserWithId) {
    const showDeleteButton = user.uid !== authUser.uid;

    return (
      <SC.User key={user.uid} onClick={() => onEditUser(user.uid)}>
        <Avatar image={user.photoUrl} name={user.displayName} />
        <SC.UserText>
          <Typography variant="label">{user.displayName}</Typography>
          <Typography variant="body">{user.email}</Typography>
        </SC.UserText>
        <Typography variant="body">{user.role}</Typography>
        {showDeleteButton && <IconButton id={user.uid} onClick={onDeleteUser} iconName="trash" stopPropagation />}
      </SC.User>
    );
  }

  return (
    <Card>
      <SC.Wrapper>
        <SC.Header>
          <SearchInput onSearch={value => {}} border="none" placeholder={t('users.searchPlaceholder')} />
          <Button icon="plus" onClick={() => onEditUser()}>
            {t('users.addUserButton')}
          </Button>
        </SC.Header>
        {isLoadingUsers ? (
          <Loader variant={LoaderEnum.Page} />
        ) : (
          <SC.UserList>{users.length ? users.map(user => getUser(user)) : 'no search results'}</SC.UserList>
        )}
      </SC.Wrapper>
    </Card>
  );
}
