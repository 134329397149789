import styled from 'styled-components';

export const IntegrationList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: ${({ theme }) => theme.spacing.s5};

  @media (max-width: 1023px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 575px) {
    grid-template-columns: 1fr;
  }
`;

export const IntegrationItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.s1};
`;

export const IntegrationItemWrapper = styled.div`
  cursor: pointer;
`;

export const IntegrationItemHead = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.s2};
`;

export const IntegrationItemBody = styled.div`
  padding-left: ${({ theme }) => theme.spacing.s8};
`;
