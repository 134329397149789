import { ThreeDots } from 'react-loader-spinner';
import * as SC from './styles';
import { useColors } from '../../hooks/theme';
import { IMessageWithId } from '@tuler/shared';
import IconMenu, { IMenuItem } from '../IconMenu/IconMenu';
import { useTranslation } from 'react-i18next';
import { formatMessageDate } from '../../common';

interface IProps {
  isAssistant: boolean;
  message?: IMessageWithId;
  typing?: boolean;
  onSetMessageNeedsAttention?: (id: string) => void;
  onAnswerMessage?: (message: IMessageWithId) => void;
}

function convertStringToLink(text: string, linkColor: string) {
  const regex = /\[([^\]]+)\]\((http[^)]+)\)/;
  const match = text.match(regex);

  if (match) {
    const [fullMatch, linkText, linkUrl] = match;
    return (
      <>
        {text.split(fullMatch)[0]}
        <SC.MessageUrl href={linkUrl} target="_blank" rel="noopener noreferrer" $color={linkColor}>
          {linkText}
        </SC.MessageUrl>
        {text.split(fullMatch)[1]}
      </>
    );
  }

  return text;
}

export default function ChatMessage({ isAssistant, message, typing = false, onSetMessageNeedsAttention, onAnswerMessage }: IProps) {
  const { t } = useTranslation();
  const colors = useColors();
  const loaderColor = !isAssistant ? colors.gray400 : colors.white;
  const createdAt = formatMessageDate(message?.createdAt);
  const linkColor = !isAssistant ? colors.brand.default : colors.white;
  const updatedMessage = message?.content ? convertStringToLink(message.content, linkColor) : '';
  const menuItems: IMenuItem[] = [
    {
      iconName: 'qa',
      title: t('chats.answer'),
      onClick: () => onAnswerMessage && message && onAnswerMessage(message),
    },
    {
      iconName: 'x-circle',
      title: t('chats.dismiss'),
      onClick: () => onSetMessageNeedsAttention && message && onSetMessageNeedsAttention(message.id),
    },
  ];

  return (
    <SC.Container $isAssistant={isAssistant} key={message?.createdAt || 'typing'}>
      <SC.Row $isAssistant={isAssistant}>
        {message?.needsAttention && (
          <IconMenu
            position="left"
            buttonIcon="danger"
            buttonColor={colors.red500}
            menuItems={menuItems}
            buttonHeight={2.5}
            buttonWidth={2.5}
          />
        )}
        <SC.Message $isAssistant={isAssistant}>
          {typing ? (
            <ThreeDots height="22" width="22" radius="9" color={loaderColor} ariaLabel="three-dots-loading" visible />
          ) : (
            updatedMessage
          )}
        </SC.Message>
      </SC.Row>
      {createdAt && <SC.CreatedAt>{createdAt}</SC.CreatedAt>}
    </SC.Container>
  );
}
