import { ReactNode } from 'react';
import * as SC from './styles';
import Icon from '../Icon/Icon';
import { useColors } from '../../hooks/theme';
import { Typography } from '../Typography/Typography';

export enum EModalSize {
  small = '40rem',
  medium = '60rem',
  large = '74rem',
}

interface IModalProps {
  children: ReactNode;
  title?: string;
  onClose?: () => void;
  size?: EModalSize;
  withoutClose?: boolean;
  height?: EModalSize;
}

function Modal({ children, title, onClose, size = EModalSize.medium, withoutClose, height }: IModalProps) {
  const colors = useColors();

  function handleClose() {
    onClose && onClose();
  }

  return (
    <SC.Modal>
      <SC.ModalBackground onClick={handleClose} />
      <SC.ModalContent $size={size} $height={height}>
        {!!title && (
          <SC.ModalHeader>
            <Typography variant="h2">{title}</Typography>
            <SC.ModalHeaderClose onClick={handleClose}>
              <Icon name="x" />
            </SC.ModalHeaderClose>
          </SC.ModalHeader>
        )}
        <SC.ModalBody>
          {!title && !withoutClose && (
            <SC.ModalBodyClose onClick={handleClose}>
              <Icon name="x" color={colors.gray900} width={2.4} height={2.4} />
            </SC.ModalBodyClose>
          )}
          <SC.ModalBodyChildren>{children}</SC.ModalBodyChildren>
        </SC.ModalBody>
      </SC.ModalContent>
    </SC.Modal>
  );
}

export default Modal;
