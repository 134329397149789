import React, { ChangeEvent, useEffect, useState } from 'react';
import * as SC from './styles';
import Icon from '../Icon/Icon';
import { Controller, UseFormSetValue } from 'react-hook-form';

interface UploadImageProps {
  name: string;
  control: any;
  image: string;
  setValue: UseFormSetValue<any>;
}

function UploadImage({ name, image, control, setValue }: UploadImageProps) {
  const [img, setImage] = useState<string>(image);

  function handleUpload(e: ChangeEvent<HTMLInputElement>, onChange: (e: any) => void) {
    if (e?.target?.files && e?.target?.files?.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(e?.target?.files[0]);
      reader.onload = async () => {
        const base64String = reader.result as string;
        setImage(base64String);
        onChange(base64String);
      };
    }
  }

  function handleDelete(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    setValue(name, '', { shouldDirty: true });
    setImage('');
  }

  useEffect(() => {
    setImage(image);
  }, [image]);

  return (
    <SC.Wrapper htmlFor="file-input" $isEmpty={!img}>
      {img ? <SC.Image src={img} /> : <Icon name="user" />}
      <SC.Actions>
        {img ? (
          <SC.ActionsButton onClick={handleDelete} type="button">
            <Icon name="trash" />
          </SC.ActionsButton>
        ) : (
          <SC.ActionsLabel>
            <Icon name="camera" />
            <Controller
              name={name}
              control={control}
              render={({ field }) => (
                <SC.UploadInput
                  id="file-input"
                  type="file"
                  accept="image/png, image/jpg, image/jpeg"
                  onChange={e => handleUpload(e, field.onChange)}
                />
              )}
            />
          </SC.ActionsLabel>
        )}
      </SC.Actions>
    </SC.Wrapper>
  );
}

export default UploadImage;
