import * as SC from './styles';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/Layout/Layout';
import PageContent from '../../components/PageContent/PageContent';
import { IIntegrationsState, ISmartmovingFilter, ISmartmovingOpportunityListItem } from '../../redux/integrations/integrations.types';
import { ICompanyState } from '../../redux/company/company.types';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  getAircallUsers,
  getSmartmovingOpportunities,
  setSmartmovingOpportunitiesFilter,
} from '../../redux/integrations/integrations.redux';
import Table, { TableChangeOptions, TableColumnCustom } from '../../components/Table/Table';
import { usePush } from '../../hooks/navigation';
import { ROUTES } from '../../constants/routes';
import { getSmartmovingOpportunityStatus } from '../../common';
import CopyItem from '../../components/CopyItem/CopyItem';
import AccountManagerSelect from '../../components/AccountManagerSelect/AccountManagerSelect';
import { SMARTMOVING_STATUSES } from '../../constants';

export default function SmartmovingOpportunitiesPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const push = usePush();
  const { id: companyId } = useSelector((state: { company: ICompanyState }) => state.company.company);
  const { smartmovingOpportunities, isSmartmovingOpportunitiesLoading, smartmovingOpportunitiesFilter, aircallUsers } = useSelector(
    (state: { integrations: IIntegrationsState }) => state.integrations,
  );

  const columns: TableColumnCustom<ISmartmovingOpportunityListItem>[] = [
    {
      title: t('smartmovingOpportunities.jobNumbersColumn'),
      dataIndex: 'jobNumbers',
      key: 'jobNumbers',
      width: 100,
      render: jobNumbers => {
        return jobNumbers.map((number: string) => (
          <div key={number}>
            <CopyItem key={number} value={number} />
          </div>
        ));
      },
    },
    {
      title: t('smartmovingOpportunities.customerNameColumn'),
      dataIndex: 'customerName',
      key: 'customerName',
      fixed: 'left',
      width: 145,
      canCopy: true,
    },
    {
      title: t('smartmovingOpportunities.opportunityStatusColumn'),
      dataIndex: 'opportunityStatus',
      key: 'opportunityStatuses',
      width: 145,
      filters: SMARTMOVING_STATUSES.map(status => ({
        text: status.name,
        value: status.value,
      })),
      render: status => {
        return getSmartmovingOpportunityStatus(status);
      },
    },
    {
      title: t('smartmovingOpportunities.salesPersonColumn'),
      dataIndex: 'salesPerson',
      key: 'salesPersons',
      width: 120,
      canCopy: true,
      filters: aircallUsers.map(user => ({
        text: user.name.replace(' Sales', ''),
        value: user.name.replace(' Sales', ''),
      })),
    },
    {
      title: t('smartmovingOpportunities.accountManagerColumn'),
      dataIndex: 'accountManager',
      key: 'accountManagers',
      width: 120,
      filters: aircallUsers.map(user => ({
        text: user.name,
        value: user.name,
      })),
      render: (text, record) => (
        <SC.SelectWrapper>
          <AccountManagerSelect opportunityId={record.id} defaultValue={record.accountManager} />
        </SC.SelectWrapper>
      ),
    },
    {
      title: t('smartmovingOpportunities.phoneNumbersColumn'),
      dataIndex: 'phoneNumbers',
      key: 'phoneNumbers',
      width: 120,
      render: phoneNumbers => {
        return phoneNumbers.map((number: string) => (
          <div key={number}>
            <CopyItem key={number} value={number} />
          </div>
        ));
      },
    },

    {
      title: t('smartmovingOpportunities.updatedAtColumn'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      defaultSortOrder: 'descend',
      width: 150,
      sorter: true,
      isRangePicker: true,
    },
    {
      title: t('smartmovingOpportunities.createdAtColumn'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      sorter: true,
      isRangePicker: true,
    },
  ];

  function handleFilter(options: TableChangeOptions) {
    const { filters, ...rest } = options;
    dispatch(setSmartmovingOpportunitiesFilter({ ...rest, ...filters } as ISmartmovingFilter));
  }

  function onOpportunityClick(id: string) {
    push(ROUTES.smartmovingOpportunity.path.replace(':id', id));
  }

  useEffect(() => {
    if (!companyId) return;
    dispatch(getSmartmovingOpportunities(companyId));
  }, [companyId, smartmovingOpportunitiesFilter]);

  useEffect(() => {
    if (!companyId) return;
    dispatch(getAircallUsers(companyId));
  }, [companyId]);

  return (
    <Layout>
      <PageContent hasBack padding="small" title={t('smartmovingOpportunities.title')}>
        <Table
          size="small"
          columns={columns}
          data={smartmovingOpportunities}
          loading={isSmartmovingOpportunitiesLoading}
          currentPage={smartmovingOpportunitiesFilter.page ?? 1}
          onChange={handleFilter}
          onClickRow={onOpportunityClick}
        />
      </PageContent>
    </Layout>
  );
}
