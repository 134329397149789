import styled from 'styled-components';
import { WithCustomScrollbar } from '../../GlobalStyle';

export const SidebarWrapper = styled.aside`
  position: sticky;
  top: 0;
  width: 30rem;
  height: 100vh;
  display: grid;
  grid-template-rows: 1fr;

  @media (max-width: 1023px) {
    width: 100%;
    grid-template-rows: auto 1fr;
    height: auto;
    background: ${({ theme }) => theme.colors.bg.layout};
    z-index: 2;
  }
`;

export const SidebarHeader = styled.div`
  height: 6rem;
  padding: 0.9rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  box-shadow: ${({ theme }) => theme.shadows.sm};
  @media (min-width: 1023px) {
    display: none;
  }
`;

export const SidebarHeaderLogo = styled.div`
  display: flex;
  align-items: center;
  gap: 1.3rem;
`;

export const SidebarHeaderBurger = styled.div<{ $open: boolean }>`
  position: relative;
  flex-direction: column;
  width: 25px;
  height: 20px;
  overflow: hidden;

  span {
    position: absolute;
    left: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 0.2rem;
    background: ${({ theme }) => theme.colors.black};
    transition: all 0.2s;

    &:nth-child(1) {
      top: 0;
      ${({ $open }) => ($open ? 'transform-origin: left top;transform: rotate(45deg) translateX(3px) translateY(-3px);' : '')}
    }

    &:nth-child(2) {
      top: calc(50% - 1px);
      ${({ $open }) => ($open ? 'transform: translateX(-100%);' : '')}
    }

    &:nth-child(3) {
      bottom: 0;
      ${({ $open }) => ($open ? 'transform-origin: left top;transform: rotate(-45deg) translateX(2px) translateY(2px);' : '')}
    }
  }
`;

export const Sidebar = styled.div<{ $open: boolean }>`
  padding: ${({ theme }) => theme.spacing.s6} !important;
  display: flex;
  flex-direction: column;

  transition: all 0.3s;
  overflow-y: auto;
  ${WithCustomScrollbar}
  @media (max-width: 1023px) {
    position: fixed;
    top: 6rem;
    left: ${({ $open }) => ($open ? '0' : '-100%')};
    right: 0;
    bottom: 0;
    width: 100%;

    background: ${({ theme }) => theme.colors.bg.layout};
  }
`;

export const SidebarButton = styled.button`
  margin-top: auto;
  padding: 0.8rem 1.2rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;

  cursor: pointer;
  border: none;
  background: none;

  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.gray900} !important;
    }
    p {
      color: ${({ theme }) => theme.colors.gray900} !important;
    }
  }
`;
