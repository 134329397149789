import { useTranslation } from 'react-i18next';
import * as SC from './styles';
import { useDropzone } from 'react-dropzone';
import Icon from '../Icon/Icon';
import { logger } from '../../logger';
import { Typography } from '../Typography/Typography';
import { uploadDocument } from '../../redux/documents/documents.redux';
import { useDispatch } from 'react-redux';
import { showToast } from '../../redux/toasts/toasts.redux';

export default function Documents() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { getRootProps } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    },
    multiple: false,
    maxSize: 31457280,
    onDropAccepted(files) {
      handleUploadFiles(files[0]);
    },
    onDropRejected: fileRejections => {
      fileRejections.forEach(fileRejection => {
        fileRejection.errors.forEach(error => {
          dispatch(showToast({ type: 'error', message: error.message }));
          logger.error(`${fileRejection.file.name}: ${error.message}`);
        });
      });
    },
  });

  function handleUploadFiles(file: File) {
    dispatch(uploadDocument(file));
  }

  return (
    <>
      <SC.Wrapper {...getRootProps()}>
        <SC.UploadDocument>
          <Icon name="upload" width={3} height={3} />
          <SC.UploadDocumentText>
            <Typography variant="h3" color="default">
              {t('documents.uploadZoneTitle')}
            </Typography>
            <Typography variant="subtitle1" color="default">
              {t('documents.uploadZoneDesc')}
            </Typography>
            <Typography variant="caption">{t('documents.uploadZoneAccept')}</Typography>
          </SC.UploadDocumentText>
        </SC.UploadDocument>
      </SC.Wrapper>
    </>
  );
}
