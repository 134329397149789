import { useTranslation } from 'react-i18next';
import * as SC from './styles';
import { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useColors } from '../../hooks/theme';
import IconButton from '../IconButton/IconButton';
import { TYPEFORM_URL } from '../../constants';
import { useDispatch } from 'react-redux';
import { showToast } from '../../redux/toasts/toasts.redux';

interface IChatMessageInputProps {
  onSubmit: () => void;
  onChange: (message: string) => void;
  message: string;
  disabled?: boolean;
  sessionId: string;
}

export default function ChatMessageInput({ onSubmit, onChange, disabled = false, message, sessionId }: IChatMessageInputProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const colors = useColors();
  const [textAreaHeight, setTextAreaHeight] = useState(42);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const isSendDisabled = message.length < 1;

  function handleChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    onChange(event.target.value);
  }

  function handleKeyDownEnter(event: KeyboardEvent<HTMLTextAreaElement>) {
    if (event.key === 'Enter' && !event.shiftKey && !disabled) {
      event.preventDefault();
      onSubmit();
    }
  }

  function calculateTextAreaHeight() {
    if (!message) return setTextAreaHeight(53);

    if (!textareaRef.current) return;
    const linesHeight = textareaRef.current.scrollHeight;
    const height = Math.floor(linesHeight / 21) * 21;

    if (height > 150) return setTextAreaHeight(150);
    if (height < 53) return setTextAreaHeight(53);
    if (height > 53 && height < 150) return setTextAreaHeight(height);
  }

  function copyQuoteLink() {
    const url = `${TYPEFORM_URL}?session_id=${sessionId}`;
    const formattedText = ` [Quote Form](${url})`;

    onChange(message + formattedText);
    dispatch(
      showToast({
        message: t('chats.quoteLinkCopied'),
      }),
    );
  }

  useEffect(() => {
    calculateTextAreaHeight();
  }, [message]);

  return (
    <SC.Container>
      <SC.InputWrapper>
        <SC.Textarea
          placeholder="Start typing here..."
          value={message}
          ref={textareaRef}
          onChange={handleChange}
          style={{ height: textAreaHeight }}
          onKeyDown={handleKeyDownEnter}
        />
        <SC.ButtonWrapper>
          <IconButton
            isDisabled={isSendDisabled}
            onClick={onSubmit}
            iconName="send"
            iconColor={colors.brand.default}
            width={2.4}
            height={2.4}
          />
        </SC.ButtonWrapper>
        <SC.ButtonWrapper>
          <IconButton onClick={copyQuoteLink} iconName="clipboard-list" iconColor={colors.text.secondary} width={2.4} height={2.4} />
        </SC.ButtonWrapper>
      </SC.InputWrapper>
    </SC.Container>
  );
}
