import { ROUTES, TPageName } from '../../constants/routes';
import * as SC from './styles';
import { useTranslation } from 'react-i18next';
import { Typography } from '../../components/Typography/Typography';
import Layout from '../../components/Layout/Layout';
import PageContent from '../../components/PageContent/PageContent';
import Card from '../../components/Card/Card';
import Image from '../../components/Image/Image';
import CustomLink from '../../components/CustomLink/CustomLink';
import { useSelector } from 'react-redux';
import { IAuthUserState } from '../../redux/authUser/authUser.types';

export default function IntegrationsPage() {
  const { t } = useTranslation();
  const { user } = useSelector((state: { authUser: IAuthUserState }) => state.authUser);

  const integrations: { icon: string; name: TPageName; path: string }[] = [
    {
      icon: 'telegram.svg',
      name: 'telegramChats',
      path: ROUTES.telegramChats.path,
    },
    {
      icon: 'telegram.svg',
      name: 'telegramNotifications',
      path: ROUTES.telegramNotifications.path,
    },
    {
      icon: 'aircall.svg',
      name: 'aircall',
      path: ROUTES.aircall.path,
    },
    {
      icon: 'smartmoving.svg',
      name: 'smartmoving',
      path: ROUTES.smartmoving.path,
    },
    {
      icon: 'smartmoving.svg',
      name: 'smartmovingOpportunities',
      path: ROUTES.smartmovingOpportunities.path,
    },
    {
      icon: 'trello.svg',
      name: 'trello',
      path: ROUTES.trello.path,
    },
  ];

  function renderIntegrations() {
    return integrations
      .filter(item => ROUTES[item.name].permissions.includes(user.role))
      .map(integration => {
        return (
          <CustomLink href={integration.path} key={integration.name}>
            <Card padding="medium" isFullHeight>
              <SC.IntegrationItem>
                <SC.IntegrationItemHead>
                  <Image src={`/integrations/${integration.icon}`} alt={integration.name} />
                  <Typography variant="h4">{t(`integrations.${integration.name}ItemTitle`)}</Typography>
                </SC.IntegrationItemHead>
                <SC.IntegrationItemBody>
                  <Typography variant="caption">{t(`integrations.${integration.name}ItemDescription`)}</Typography>
                </SC.IntegrationItemBody>
              </SC.IntegrationItem>
            </Card>
          </CustomLink>
        );
      });
  }

  return (
    <Layout>
      <PageContent title={t('integrations.title')}>
        <SC.IntegrationList>{renderIntegrations()}</SC.IntegrationList>
      </PageContent>
    </Layout>
  );
}
