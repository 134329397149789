import { DateTime } from 'luxon';
import TurndownService from 'turndown';
import { EDateFilter } from './types';
import { SMARTMOVING_STATUSES } from '../constants';

/**
 * toCamelCase - given a string returns the camelCase of that string
 * @param str
 * @returns
 */
export function toCamelCase(str: string) {
  // Split the string into an array of words
  const words = str.split(/[\s_-]/);

  // Capitalize the first letter of each word except the first word
  const camelCaseWords = words.map((word, index) => {
    if (index === 0) {
      return word.toLowerCase();
    }
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  // Join the words together into a single string
  return camelCaseWords.join('');
}

/**
 * updates string into a string that only contain valid floats or prices
 * @param inputValue
 * @param isPrice - wether to treat the string as a price or not (ex: extra padding 0s)
 * @returns
 */
export function formatFloat(inputValue: string | number, isPrice: boolean): string {
  if (typeof inputValue === 'number') {
    inputValue = inputValue.toString();
  }
  const floatFormat = inputValue.replace(/[^0-9.]/g, '');

  if (!floatFormat) {
    return isPrice ? '0.00' : '0';
  }

  let [wholePart, decimalPart] = floatFormat.split('.');

  if (decimalPart === undefined) {
    decimalPart = isPrice ? '00' : '';
  }

  wholePart = (parseInt(wholePart, 10) || 0).toString();

  let formattedFloat = wholePart;

  if (isPrice) {
    formattedFloat += '.' + decimalPart.slice(0, 2).padEnd(2, '0');
  } else if (decimalPart) {
    formattedFloat = parseFloat(formattedFloat + '.' + decimalPart).toString();
  }

  return formattedFloat;
}

/**
 * updates number values to be in price format or revert them back to number format
 * @param obj
 * @param toPrice - if true converts to price string. If false converts to number.
 * @returns
 */
export function updatePrices(obj: Record<string, any>, toPrice: boolean = true) {
  const updatedObject = { ...obj };
  for (const key in updatedObject) {
    if (Object.prototype.hasOwnProperty.call(updatedObject, key)) {
      if (key === 'price') {
        updatedObject[key] = toPrice ? formatFloat(updatedObject[key].toString(), true) : parseFloat(updatedObject[key].toString());
      } else if (Array.isArray(updatedObject[key])) {
        updatedObject[key] = updatedObject[key].map((item: Record<string, any>) => {
          if (typeof item === 'object' && item !== null) {
            return updatePrices(item, toPrice);
          }
          return item;
        });
      } else if (typeof updatedObject[key] === 'object' && updatedObject[key] !== null) {
        updatedObject[key] = updatePrices(updatedObject[key], toPrice);
      }
    }
  }
  return updatedObject;
}

/**
 * Sorts the given object by the "order" property in each value.
 * @param obj The input object to be sorted.
 * @returns A new object with keys sorted by "order".
 */
export function sortObjectByOrder(obj: Record<string, any>): Record<string, any> {
  return Object.entries(obj)
    .sort(([, a], [, b]) => a.order - b.order)
    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
}

/**
 * capitalize - Capitalizes the first letter of a string.
 * @param str - The input string.
 * @returns The string with the first letter capitalized.
 */
export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * htmlToMarkdown - Converts HTML to Markdown using TurndownService.
 * @param htmlText - The input HTML text.
 * @returns A Promise that resolves to the Markdown string.
 */
export async function htmlToMarkdown(htmlText: string): Promise<string> {
  const turndownService = new TurndownService();
  const markdown = turndownService.turndown(htmlText);
  return markdown;
}

/**
 * standardizeDomain - Converts URL to standardized domain.
 * @param str - The input string.
 * @returns A Promise that resolves to the string.
 */
export function standardizeDomain(url: string): string {
  return url
    .replace(/^(?:https?:\/\/)?(?:www\.)?/, '') // Remove protocol and www
    .split('/')[0]; // Remove any path
}

/**
 * convertNameToColor - returns a hex color by text.
 * @param str - The name string.
 * @returns hex color.
 */
export function convertNameToColor(name: string) {
  const palette = [
    '#0099FF',
    '#FF4363',
    '#FFC464',
    '#21CB92',
    '#0099FF',
    '#1C64F2',
    '#0E9F6E',
    '#0694A2',
    '#5850EC',
    '#7E3AF2',
    '#E74694',
    '#F05252',
    '#FF5A1F',
    '#20B2AA',
    '#FF69B4',
  ];
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }

  const index = Math.abs(hash) % palette.length;

  return palette[index];
}

/**
 * formatMessageDate - Formats the date value to display either time or date.
 * If date is on the same day as the current date, it shows only time. Otherwise, it shows date and time.
 * @param date - The date value to format.
 * @returns The formatted date value (time or date).
 */
export function formatMessageDate(date?: string): string | undefined {
  if (!date) return undefined;

  const dateTime = DateTime.fromISO(date);
  const currentDate = DateTime.local().startOf('day');

  if (dateTime.hasSame(currentDate, 'day')) {
    return dateTime.toLocaleString(DateTime.TIME_SIMPLE);
  } else {
    return dateTime.toLocaleString(DateTime.DATETIME_SHORT);
  }
}

/**
 * getDateFilter - Returns start and end dates based on the specified date filter.
 * @param dateFilter - The date filter to apply (defaults to EDateFilter.today).
 * @returns An object containing start and end dates.
 */

export function getDateFilter(dateFilter: EDateFilter = EDateFilter.today) {
  const filters = {
    today: {
      start: DateTime.now().startOf('day').startOf('day').toUTC().toISO(),
      end: DateTime.now().endOf('day').endOf('day').toUTC().toISO(),
    },
    yesterday: {
      start: DateTime.now().minus({ days: 1 }).startOf('day').toUTC().toISO(),
      end: DateTime.now().minus({ days: 1 }).endOf('day').toUTC().toISO(),
    },
    thisWeek: {
      start: DateTime.now().startOf('week').toUTC().toISO(),
      end: DateTime.now().endOf('week').toUTC().toISO(),
    },
    lastWeek: {
      start: DateTime.now().minus({ weeks: 1 }).startOf('week').toUTC().toISO(),
      end: DateTime.now().minus({ weeks: 1 }).endOf('week').toUTC().toISO(),
    },
    thisMonth: {
      start: DateTime.now().startOf('month').toUTC().toISO(),
      end: DateTime.now().endOf('month').toUTC().toISO(),
    },
    lastMonth: {
      start: DateTime.now().minus({ months: 1 }).startOf('month').toUTC().toISO(),
      end: DateTime.now().minus({ months: 1 }).endOf('month').toUTC().toISO(),
    },
  };

  return filters[dateFilter] as { start: string; end: string };
}

/**
 * Plays a notification sound based on the specified sound type.
 * @param soundType The type of sound to play.
 */
export function playNotificationSound() {
  const audio = new Audio('/sounds/new-message.mp3');
  audio.play();
}

/**
 * Copy text.
 * @param text text.
 */
export function copyItem(text: string) {
  navigator.clipboard.writeText(text);
}

/**
 * Formats a date into a human-readable string using Luxon.
 * @param date The date to format.
 * @param format The format to use.
 * @returns The formatted date string.
 */
export function formatDate(date: string | DateTime, format: 'humanReadable' | 'humanReadableWithTime' | 'database'): string {
  const dateInstance = DateTime.isDateTime(date) ? date : DateTime.fromISO(date);

  switch (format) {
    case 'humanReadable':
      return dateInstance.toFormat('MMM dd, yyyy');
    case 'humanReadableWithTime':
      return dateInstance.toFormat('MMM dd, yyyy h:mm a');
    case 'database':
      return dateInstance.toFormat('yyyy-MM-dd');
  }
}

/**
 * Converts a numeric status code to its corresponding smartmoving opportunity status string.
 * @param status The numeric status code.
 * @returns The string representation of the status.
 */
export function getSmartmovingOpportunityStatus(status: number): string {
  const statusObj = SMARTMOVING_STATUSES.find(s => s.value === status);
  return statusObj ? statusObj.name : 'Unknown';
}

/**
 * Converts a numeric smartmoving phone type code to its corresponding string representation.
 * @param type The numeric smartmoving phone type code.
 * @returns The string representation of the phone type.
 */
export function getSmartmovingPhoneType(type: number): string {
  switch (type) {
    case 0:
      return 'Mobile';
    case 1:
      return 'Home';
    case 2:
      return 'Office';
    case 3:
      return 'Other';
    default:
      return 'Unknown';
  }
}
