import React from 'react';
import ReactPaginate from 'react-paginate';
import * as SC from './styles';
import Icon from '../Icon/Icon';

interface PaginationProps {
  activePage: number;
  onPageChange: (selectedPage: number) => void;
  pageCount: number;
}

function Pagination({ activePage, onPageChange, pageCount }: PaginationProps) {
  return (
    <SC.PaginationContainer>
      <ReactPaginate
        // - 1 for start from 1
        forcePage={activePage - 1}
        containerClassName="pagination"
        activeClassName="active"
        breakClassName="page-item"
        breakLinkClassName="page-link"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item page-arrow"
        previousLinkClassName="page-link"
        nextClassName="page-item page-arrow"
        nextLinkClassName="page-link"
        breakLabel="..."
        previousLabel={<Icon name="chevron-left" />}
        nextLabel={<Icon name="chevron-right" />}
        onPageChange={e => onPageChange(e.selected + 1)}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        pageCount={pageCount}
        renderOnZeroPageCount={() => null}
      />
    </SC.PaginationContainer>
  );
}

export default Pagination;
