import { IQAState, IQATag, IQATagColor } from '../../redux/qa/qa.types';
import { Typography } from '../Typography/Typography';
import * as SC from './styles';
import { useTranslation } from 'react-i18next';
import { DndContext, MouseSensor, closestCorners, TouchSensor, useSensor, useSensors, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';
import { useMemo, useRef, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Icon from '../Icon/Icon';
import { useColors } from '../../hooks/theme';
import IconButton from '../IconButton/IconButton';
import QATagsMenuDetails from '../QATagsMenuDetails/QATagsMenuDetails';
import useOnClickOutside from '../../hooks/useOnClickOutside';

interface ITagsMenuProps {
  inputValue: string;
  companyTags: IQATag[];
  onUpdate: (tag: IQATag) => void;
  onCreate: () => void;
  onAdd: (id: number) => void;
  addedTags?: number[];
  onUpdateOrder: (id: number, order: number) => void;
  tagOrder: number[];
  tagColors: IQATagColor[];
  isTop: boolean;
  onDelete: (id: number) => void;
  editTagId?: number;
  onSetEditTag: (id?: number) => void;
}

export default function TagsMenu({
  inputValue,
  companyTags,
  onUpdate,
  onCreate,
  onAdd,
  addedTags,
  onUpdateOrder,
  tagOrder,
  tagColors,
  isTop,
  onDelete,
  editTagId,
  onSetEditTag,
}: ITagsMenuProps) {
  const { t } = useTranslation();
  const colors = useColors();
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const showCreateTag = !!inputValue && !companyTags.find(tag => tag.label === inputValue);
  const filteredTags = getFilteredTags();
  const listWrapperRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(listWrapperRef, () => onSetEditTag(undefined));

  function getFilteredTags() {
    const tags: IQATag[] = tagOrder.map(item => companyTags.find(tag => tag.id === item)!);

    if (editTagId) {
      return tags.filter(tag => tag.id === editTagId);
    }

    if (inputValue) {
      return tags.filter(tag => tag.label.toLowerCase().includes(inputValue.toLowerCase()));
    }

    return tags;
  }

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (active.id !== over?.id) {
      onUpdateOrder(active.id as number, over?.data.current?.sortable.index);
    }
  }

  function stopPropagation(e: any) {
    e.stopPropagation();
  }

  function handleOpenEdit(id: number) {
    if (id === editTagId) {
      onSetEditTag(undefined);
    } else {
      onSetEditTag(id);
    }
  }

  function getTagDetailsModal(id: number) {
    const tag = companyTags.find(tag => tag.id === id);
    if (!tag) return;

    return <QATagsMenuDetails tag={tag} colors={tagColors} isTop={isTop} onDelete={onDelete} onUpdate={onUpdate} />;
  }

  function GetMenuTag({ tag }: { tag: IQATag }) {
    const { isDragging, attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: tag.id });
    const color = tagColors.find(color => color.id === tag?.colorId);
    const isAdded = !!addedTags?.includes(tag.id);
    const isEdit = editTagId === tag.id;

    const style = {
      transform: CSS.Translate.toString(transform),
      transition,
      opacity: isDragging ? 0.3 : 1,
    };

    return (
      <SC.TagsMenuItem ref={setNodeRef} style={style} onClick={() => onAdd(tag.id)} $isAdded={isAdded}>
        <SC.TagsMenuItemHandle {...listeners} {...attributes}>
          <Icon name="drag" width={2} height={2} color={colors.text.body} />
        </SC.TagsMenuItemHandle>
        <SC.TagsMenuItemText $textColor={color?.color ?? ''} $backgroundColor={color?.secondaryColor ?? ''} title={tag.label}>
          {tag.label}
        </SC.TagsMenuItemText>
        <div onClick={stopPropagation}>
          <IconButton
            iconName={isEdit ? 'chevron-down' : 'dots-horizontal'}
            id={tag.id}
            onClick={handleOpenEdit}
            withBackground
            backgroundColor={isEdit ? undefined : 'transparent'}
          />
        </div>
      </SC.TagsMenuItem>
    );
  }

  const getDndContext = useMemo(() => {
    return (
      <DndContext
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
      >
        <SortableContext items={filteredTags} strategy={verticalListSortingStrategy}>
          <SC.TagsMenuList>
            {filteredTags.map(tag => (
              <GetMenuTag key={'menu-tag' + tag.id} tag={tag} />
            ))}
          </SC.TagsMenuList>
        </SortableContext>
      </DndContext>
    );
  }, [filteredTags, addedTags, editTagId, companyTags]);

  return (
    <SC.TagsMenu $isTop={isTop} onClick={stopPropagation}>
      <SC.TagsMenuTitle>
        <Typography variant="caption">{t('qa.tagsMenuTitle')}</Typography>
      </SC.TagsMenuTitle>
      <div ref={listWrapperRef}>
        {!!filteredTags.length && getDndContext}
        {!!editTagId && getTagDetailsModal(editTagId)}
      </div>
      {showCreateTag && (
        <SC.TagsMenuNew onClick={onCreate}>
          <Typography variant="caption" color="default">
            {t('qa.tagsMenuCreate')}
          </Typography>
          <SC.TagsMenuNewTag>{inputValue}</SC.TagsMenuNewTag>
        </SC.TagsMenuNew>
      )}
    </SC.TagsMenu>
  );
}
