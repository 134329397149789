import React from 'react';
import Loader, { LoaderEnum } from '../Loader/Loader';
import { useSelector } from 'react-redux';
import { ILoaderState } from '../../redux/loader/loader.types';
import EmptyContent from '../EmptyContent/EmptyContent';
import * as SC from './styles';
import Button from '../Button/Button';
import { IconName } from '../Icon/Icon';
import { Typography } from '../Typography/Typography';
import IconButton from '../IconButton/IconButton';
import { useGoBack } from '../../hooks/navigation';
import { useColors } from '../../hooks/theme';

export type TPagePadding = 'small' | 'medium';

interface IProps {
  children: React.ReactNode;
  title?: string;
  hasData?: boolean;
  emptyIcon?: IconName;
  emptyTitle?: string;
  emptyDescription?: string;
  emptyButtonText?: string;
  actions?: React.ReactNode;
  hasBack?: boolean;
  hideActionButton?: boolean;
  actionButtonText?: string;
  onClickAction?: () => void;
  subHeader?: React.ReactNode;
  withoutChildrenPadding?: boolean;
  padding?: TPagePadding;
  notScrollableContent?: boolean;
}

export default function PageContent({
  children,
  title,
  hasData,
  emptyIcon,
  emptyTitle,
  emptyDescription,
  emptyButtonText,
  actions,
  hasBack = false,
  hideActionButton = false,
  actionButtonText,
  onClickAction,
  subHeader,
  withoutChildrenPadding = false,
  padding = 'medium',
  notScrollableContent = false,
}: IProps) {
  const colors = useColors();
  const { isPageLoading } = useSelector((state: { loader: ILoaderState }) => state.loader);
  const isWithHeader = !!title;
  const { goBack } = useGoBack();
  const showAction = actionButtonText && hasData && !hideActionButton;
  const showActions = actions || showAction;

  function handlePressBack() {
    goBack();
  }

  function renderChildren() {
    if (!hasData && emptyTitle) {
      return (
        <EmptyContent
          icon={emptyIcon}
          title={emptyTitle}
          description={emptyDescription}
          buttonText={emptyButtonText}
          onButtonClick={onClickAction}
        />
      );
    } else {
      return children;
    }
  }

  return (
    <SC.Container $isWithoutHeader={!isWithHeader} $notScrollableContent={notScrollableContent}>
      {isWithHeader && (
        <SC.Header $padding={padding}>
          <SC.HeaderTitle>
            {hasBack && <IconButton iconName="chevron-left" onClick={handlePressBack} height={3.2} width={3.2} iconColor={colors.black} />}
            <Typography variant="h2" bold>
              {title}
            </Typography>
          </SC.HeaderTitle>

          {showActions && (
            <SC.Actions>
              {actions}
              {showAction && (
                <Button onClick={onClickAction} icon="plus">
                  {actionButtonText}
                </Button>
              )}
            </SC.Actions>
          )}
        </SC.Header>
      )}
      <SC.ChildrenWrapper $withoutPadding={withoutChildrenPadding} $padding={padding}>
        {subHeader && subHeader}
        {isPageLoading ? <Loader variant={LoaderEnum.Page} /> : renderChildren()}
      </SC.ChildrenWrapper>
    </SC.Container>
  );
}
