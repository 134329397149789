import { Reducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IIntegrationsState,
  ITelegramNotificationWithId,
  ITrelloWithId,
  ISmartmovingWithId,
  ISmartmoving,
  ITelegramNotification,
  ITrello,
  IAircallWithId,
  IAircall,
  ISmartmovingOpportunities,
  ISmartmovingOpportunityWithId,
  IAircallUser,
  ISmartmovingFilter,
} from './integrations.types';

const initialState: IIntegrationsState = {
  activeTelegramNotification: undefined,
  isTelegramNotificationLoading: false,

  activeTrello: undefined,
  isTrelloLoading: false,

  activeAircall: undefined,
  isAircallLoading: false,
  aircallUsers: [],

  activeSmartmoving: undefined,
  isSmartmovingLoading: false,

  smartmovingOpportunities: { data: [], count: 0 },
  smartmovingOpportunitiesFilter: {
    search: undefined,
    page: 1,
    pageSize: 20,
    sortBy: 'updatedAt',
    sortDesc: true,
  },
  isSmartmovingOpportunitiesLoading: false,

  activeSmartmovingOpportunity: undefined,
  isSmartmovingOpportunityLoading: false,
};

export const integrationsSlice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    getActiveTelegramNotification: (state: IIntegrationsState, action: PayloadAction<string>) => {},
    setActiveTelegramNotification: (state: IIntegrationsState, action: PayloadAction<ITelegramNotificationWithId | undefined>) => {
      state.activeTelegramNotification = action.payload;
    },
    createTelegramNotification: (state: IIntegrationsState, action: PayloadAction<ITelegramNotification>) => {},
    updateTelegramNotification: (state: IIntegrationsState, action: PayloadAction<ITelegramNotificationWithId>) => {},
    setIsTelegramNotificationLoading: (state: IIntegrationsState, action: PayloadAction<boolean>) => {
      state.isTelegramNotificationLoading = action.payload;
    },

    getActiveTrello: (state: IIntegrationsState, action: PayloadAction<string>) => {},
    setActiveTrello: (state: IIntegrationsState, action: PayloadAction<ITrelloWithId | undefined>) => {
      state.activeTrello = action.payload;
    },
    createTrello: (state: IIntegrationsState, action: PayloadAction<ITrello>) => {},
    updateTrello: (state: IIntegrationsState, action: PayloadAction<ITrelloWithId>) => {},
    setIsTrelloLoading: (state: IIntegrationsState, action: PayloadAction<boolean>) => {
      state.isTrelloLoading = action.payload;
    },

    getActiveSmartmoving: (state: IIntegrationsState, action: PayloadAction<string>) => {},
    setActiveSmartmoving: (state: IIntegrationsState, action: PayloadAction<ISmartmovingWithId | undefined>) => {
      state.activeSmartmoving = action.payload;
    },
    createSmartmoving: (state: IIntegrationsState, action: PayloadAction<ISmartmoving>) => {},
    updateSmartmoving: (state: IIntegrationsState, action: PayloadAction<ISmartmovingWithId>) => {},
    setIsSmartmovingLoading: (state: IIntegrationsState, action: PayloadAction<boolean>) => {
      state.isSmartmovingLoading = action.payload;
    },

    getActiveAircall: (state: IIntegrationsState, action: PayloadAction<string>) => {},
    setActiveAircall: (state: IIntegrationsState, action: PayloadAction<IAircallWithId | undefined>) => {
      state.activeAircall = action.payload;
    },
    createAircall: (state: IIntegrationsState, action: PayloadAction<IAircall>) => {},
    updateAircall: (state: IIntegrationsState, action: PayloadAction<IAircallWithId>) => {},
    setIsAircallLoading: (state: IIntegrationsState, action: PayloadAction<boolean>) => {
      state.isAircallLoading = action.payload;
    },

    getAircallUsers: (state: IIntegrationsState, action: PayloadAction<string>) => {},
    setAircallUsers: (state: IIntegrationsState, action: PayloadAction<IAircallUser[]>) => {
      state.aircallUsers = action.payload;
    },

    setSmartmovingOpportunitiesFilter: (state: IIntegrationsState, action: PayloadAction<ISmartmovingFilter>) => {
      state.smartmovingOpportunitiesFilter = {
        ...state.smartmovingOpportunitiesFilter,
        ...action.payload,
      };
    },
    setSmartmovingOpportunities: (state: IIntegrationsState, action: PayloadAction<ISmartmovingOpportunities>) => {
      state.smartmovingOpportunities = action.payload;
    },
    setIsSmartmovingOpportunitiesLoading: (state: IIntegrationsState, action: PayloadAction<boolean>) => {
      state.isSmartmovingOpportunitiesLoading = action.payload;
    },
    getSmartmovingOpportunities: (state: IIntegrationsState, action: PayloadAction<string>) => {},

    getSmartmovingOpportunity: (state: IIntegrationsState, action: PayloadAction<string>) => {},
    setActiveSmartmovingOpportunity: (state: IIntegrationsState, action: PayloadAction<ISmartmovingOpportunityWithId>) => {
      state.activeSmartmovingOpportunity = action.payload;
    },
    setIsSmartmovingOpportunityLoading: (state: IIntegrationsState, action: PayloadAction<boolean>) => {
      state.isSmartmovingOpportunityLoading = action.payload;
    },
    updateSmartmovingOpportunityAccountManager: (
      state: IIntegrationsState,
      action: PayloadAction<{ id: string; accountManager: string }>,
    ) => {},
  },
});

export const {
  getActiveTelegramNotification,
  setActiveTelegramNotification,
  createTelegramNotification,
  updateTelegramNotification,
  setIsTelegramNotificationLoading,

  getActiveTrello,
  setActiveTrello,
  createTrello,
  updateTrello,
  setIsTrelloLoading,

  getActiveAircall,
  setActiveAircall,
  createAircall,
  updateAircall,
  setIsAircallLoading,

  getAircallUsers,
  setAircallUsers,

  getActiveSmartmoving,
  setActiveSmartmoving,
  createSmartmoving,
  updateSmartmoving,
  setIsSmartmovingLoading,

  setSmartmovingOpportunitiesFilter,
  setSmartmovingOpportunities,
  setIsSmartmovingOpportunitiesLoading,
  getSmartmovingOpportunities,

  getSmartmovingOpportunity,
  setActiveSmartmovingOpportunity,
  setIsSmartmovingOpportunityLoading,
  updateSmartmovingOpportunityAccountManager,
} = integrationsSlice.actions;

export const integrationsReducer: Reducer<IIntegrationsState> = integrationsSlice.reducer;
