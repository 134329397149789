import styled from 'styled-components';
import { HideScrollbar } from '../../GlobalStyle';

export const Wrapper = styled.div`
  max-width: 80rem;
  width: 100%;
  margin: 0 auto;
  display: grid;
  gap: ${({ theme }) => theme.spacing.s4};
`;

export const Body = styled.div`
  padding: ${({ theme }) => theme.spacing.s7};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.s7};
  max-height: 100%;
  height: fit-content;

  border: 0.1rem solid ${({ theme }) => theme.colors.border.dark};
  border-radius: ${({ theme }) => theme.radius.l};
  overflow: auto;
  ${HideScrollbar}
`;
