import { ICall } from '../../redux/call/call.types';
import { Typography } from '../Typography/Typography';
import * as SC from './styles';
import { useTranslation } from 'react-i18next';
import Divider from '../Divider/Divider';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import FlatButton from '../FlatButton/FlatButton';

interface ICallDetails {
  call: ICall;
}

export default function CallDetails({ call }: ICallDetails) {
  const { t } = useTranslation();

  function getTranscript() {
    if (call.messages.length === 0) return 'N/A';

    return call.messages.map((item, index) => {
      const isUser = item.role === 'user';
      const key = `${call.id}-${index}`;

      return (
        <SC.MessageContainer key={key} $isUser={isUser}>
          <SC.Message $isUser={isUser}>{item.message}</SC.Message>
        </SC.MessageContainer>
      );
    });
  }

  return (
    <SC.Wrapper key={call.id}>
      <SC.AudioWrapper>
        <AudioPlayer
          src={call.recordingUrl}
          customControlsSection={[RHAP_UI.ADDITIONAL_CONTROLS, RHAP_UI.MAIN_CONTROLS, RHAP_UI.VOLUME_CONTROLS]}
          customAdditionalControls={[
            <a key="download" href={call.recordingUrl} download>
              {<FlatButton icon="download" text="download" />}
            </a>,
          ]}
          autoPlay={false}
          autoPlayAfterSrcChange={false}
        />
      </SC.AudioWrapper>

      <Divider />
      <Typography variant="h2" bold>
        {t('calls.detailsSummary')}
      </Typography>
      <Typography variant="body">{call.summary ? call.summary : 'N/A'}</Typography>
      <Divider />
      <Typography variant="h2" bold>
        {t('calls.detailsTranscript')}
      </Typography>
      <SC.Messages>{getTranscript()}</SC.Messages>
    </SC.Wrapper>
  );
}
