import { useColors } from '../../hooks/theme';
import { showToast } from '../../redux/toasts/toasts.redux';
import IconButton from '../IconButton/IconButton';
import { Typography } from '../Typography/Typography';
import * as SC from './styles';
import { useDispatch } from 'react-redux';

interface ICopyInputProps {
  title: string;
  text: string;
}

export default function CopyInput({ title, text }: ICopyInputProps) {
  const colors = useColors();
  const dispatch = useDispatch();

  function handleCopy() {
    navigator.clipboard.writeText(text);
    dispatch(showToast({ message: 'Text copied' }));
  }

  return (
    <SC.InputContainer>
      <Typography variant="label">{title}</Typography>
      <SC.InputWrapper>
        <SC.InputElement name="CopyInput" value={text} />
        <IconButton onClick={handleCopy} iconName="document-duplicate" iconColor={colors.text.secondary} width={2.4} height={2.4} />
      </SC.InputWrapper>
    </SC.InputContainer>
  );
}
