import { useDispatch, useSelector } from 'react-redux';
import { usePushQuery, useURLParams, useRemoveQuery } from '../../hooks/navigation';
import { useTranslation } from 'react-i18next';
import { ICompanyForm, ICompanyState } from '../../redux/company/company.types';
import { editCompanyValidation, editUserValidation, userValidation } from '../../schemas';
import { deleteCompany, showDeleteModal, updateCompany } from '../../redux/company/company.redux';
import {
  createUser,
  deleteUser,
  getUsers,
  sendPasswordResetEmail,
  setActiveUserId,
  showRemoveConfirmModal,
  updateUser,
} from '../../redux/users/users.redux';
import { IAuthUserState } from '../../redux/authUser/authUser.types';
import { IUser, IUserWithId, IUsersState, UserRole } from '../../redux/users/users.types';
import { useEffect } from 'react';
import EntityForm from '../../components/EntityForm/EntityForm';
import CompanyTheme from '../../components/CompanyTheme/CompanyTheme';
import UserList from '../../components/UserList/UserList';
import PromptModal from '../../components/PromptModal/PromptModal';
import * as SC from './styles';
import Layout from '../../components/Layout/Layout';
import Tabs, { ITabItem } from '../../components/Tabs/Tabs';
import FlatButton from '../../components/FlatButton/FlatButton';
import Card from '../../components/Card/Card';
import ActionCard from '../../components/ActionCard/ActionCard';
import { Typography } from '../../components/Typography/Typography';
import { FormField } from '../../common/types';
import ResetPassword from '../../components/ResetPassword/ResetPassword';
import CompanyBilling from '../../components/CompanyBilling/CompanyBilling';
import { getBillingData } from '../../redux/billing/billing.redux';
import PageContent from '../../components/PageContent/PageContent';

export type TCompanyModalTab = 'company' | 'theme' | 'users' | 'user' | 'billing';

export default function CompanySettingsPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const removeQuery = useRemoveQuery();
  const pushQuery = usePushQuery();
  const { tab, userId } = useURLParams();
  const { company, isEditCompanyLoading, deleteModalOpen } = useSelector((state: { company: ICompanyState }) => state.company);
  const { user } = useSelector((state: { authUser: IAuthUserState }) => state.authUser);
  const { users, resetPasswordSentUsers, deleteUserModalOpen, isUserEditLoading } = useSelector(
    (state: { users: IUsersState }) => state.users,
  );
  const editUser = users.find(user => user.uid === userId) || ({} as IUserWithId);
  const isResetPasswordEmailSent = resetPasswordSentUsers.includes(editUser.email);
  const companyFormKey = JSON.stringify(company);
  const editUserFormKey = JSON.stringify(editUser);
  const showDeleteCompany = user.role === UserRole.superuser;
  const canEditUserRole = editUser.uid !== user.uid;
  const userRoleOptions = [
    { label: t('users.default'), value: UserRole.default },
    { label: t('users.admin'), value: UserRole.admin },
  ];

  const companyFormFields: FormField[] = [
    {
      name: 'image',
      defaultValue: company.image || '',
      label: '',
      type: 'image',
      required: false,
    },
    {
      name: 'name',
      defaultValue: company.name || '',
      label: t('company.addCompanyName'),
      placeholder: t('company.addCompanyNamePlaceholder'),
      required: true,
    },
    {
      name: 'domain',
      defaultValue: company.domain || '',
      label: t('company.addCompanyDomain'),
      placeholder: t('company.addCompanyDomainPlaceholder'),
      required: true,
    },
    {
      name: 'industry',
      defaultValue: company.industry || '',
      label: t('company.addCompanyIndustry'),
      placeholder: t('company.addCompanyIndustryPlaceholder'),
      required: true,
    },
    {
      name: 'email',
      defaultValue: company.email || '',
      label: t('company.addCompanyEmail'),
      placeholder: t('company.addCompanyEmailPlaceholder'),
      required: true,
    },
    {
      name: 'phone',
      defaultValue: company.phone || '',
      label: t('company.addCompanyPhone'),
      placeholder: t('company.addCompanyPhonePlaceholder'),
      required: true,
      type: 'phone',
    },
  ];

  const userFields: FormField[] = [
    {
      name: 'photoUrl',
      defaultValue: editUser.photoUrl || '',
      label: t('users.uploadUserImageTitle'),
      type: 'image',
      required: false,
    },
    {
      name: 'displayName',
      defaultValue: editUser.displayName || '',
      label: t('users.addUserName'),
      placeholder: t('users.addUserNamePlaceholder'),
      required: true,
    },
    {
      name: 'email',
      defaultValue: editUser.email || '',
      label: t('users.addUserEmail'),
      placeholder: t('users.addUserEmailPlaceholder'),
      required: !!userId,
      disabled: !!userId,
    },
    {
      name: 'whatsappPhone',
      defaultValue: editUser.whatsappPhone || '',
      label: t('users.addUserWhatsApp'),
      placeholder: t('users.addUserWhatsAppPlaceholder'),
      required: false,
      type: 'phone',
    },
    {
      name: 'role',
      defaultValue: editUser.role || UserRole.default,
      options: userRoleOptions,
      label: t('users.addUserRole'),
      required: true,
      disabled: !canEditUserRole,
    },
  ];

  const tabs: ITabItem[] = [
    {
      title: t('company.companySettingsItem'),
      name: tab ? 'company' : 'undefined',
      onClick: () => {
        pushQuery({
          tab: 'company',
        });
      },
    },
    {
      title: t('company.billingSettingsItem'),
      name: 'billing',
      onClick: () => {
        pushQuery({
          tab: 'billing',
        });
      },
    },
    {
      title: t('company.themeSettingsItem'),
      name: 'theme',
      onClick: () => {
        pushQuery({
          tab: 'theme',
        });
      },
    },
    {
      title: t('company.usersSettingsItem'),
      name: tab === 'user' ? 'user' : 'users',
      onClick: () => {
        pushQuery({
          tab: 'users',
        });
      },
    },
  ];

  function handleOpenUsers() {
    pushQuery({
      tab: 'users',
    });
  }

  function handleOpenUser(id?: string) {
    pushQuery({
      tab: 'user',
      ...(id ? { userId: id } : {}),
    });
  }

  function handleUpdateCompany(data: ICompanyForm) {
    dispatch(updateCompany(data));
  }

  function handleAddUser(data: IUser) {
    if (userId) {
      dispatch(updateUser({ companyId: company.id, user: { ...data, uid: userId } }));
    } else {
      dispatch(createUser({ companyId: company.id, user: { ...data } }));
    }
  }

  function handleDeleteUser() {
    dispatch(deleteUser());
  }

  function handleCloseUserDelete() {
    dispatch(showRemoveConfirmModal(false));
  }

  function handleOpenUserDelete(id: string) {
    dispatch(setActiveUserId(id));
    dispatch(showRemoveConfirmModal(true));
  }

  function handleOpenCompanyDelete() {
    dispatch(showDeleteModal(true));
  }

  function handleDeleteCompany() {
    dispatch(deleteCompany());
  }

  function handleCloseDeleteModal() {
    dispatch(showDeleteModal(false));
  }

  function handleResetPassword() {
    dispatch(sendPasswordResetEmail(editUser.email));
  }

  useEffect(() => {
    if (!company.id) return;
    if (tab === 'billing') {
      dispatch(getBillingData(company.id));
    }
    if (!company.id || !['users', 'user'].includes(tab as TCompanyModalTab)) return;
    dispatch(getUsers());
  }, [company.id, tab]);

  useEffect(() => {
    if (tab !== 'user' && userId) {
      removeQuery(['userId']);
    }
  }, [tab]);

  function renderContent() {
    switch (tab) {
      case 'billing':
        return <CompanyBilling />;
      case 'theme':
        return <CompanyTheme />;
      case 'users':
        return <UserList onEditUser={handleOpenUser} onDeleteUser={handleOpenUserDelete} />;
      case 'user':
        return (
          <SC.CompanyDetails>
            <Card>
              <SC.UserForm>
                <SC.UserFormTitle>
                  <FlatButton icon="chevron-left" text={t('users.editUserBack')} onClick={handleOpenUsers} />
                  <Typography variant="h2">{userId ? t('users.editUserTitle') : t('users.addUserTitle')}</Typography>
                </SC.UserFormTitle>
                <EntityForm
                  id={editUser.uid}
                  key={editUserFormKey}
                  fields={userFields}
                  onSubmit={handleAddUser}
                  validationSchema={userId ? editUserValidation : userValidation}
                  isLoading={isUserEditLoading}
                />
              </SC.UserForm>
            </Card>
            {userId && <ResetPassword onReset={handleResetPassword} isResetSent={isResetPasswordEmailSent} />}
          </SC.CompanyDetails>
        );

      default:
        return (
          <SC.CompanyDetails>
            <Card>
              <EntityForm
                id={company.id}
                key={companyFormKey}
                fields={companyFormFields}
                onSubmit={handleUpdateCompany}
                validationSchema={editCompanyValidation}
                isLoading={isEditCompanyLoading}
              />
            </Card>

            {showDeleteCompany && (
              <ActionCard
                onClick={handleOpenCompanyDelete}
                variant="danger"
                buttonText={t('company.deleteCompanyCardButton')}
                title={t('company.deleteCompanyCardTitle')}
                description={t('company.deleteCompanyCardDesc')}
              />
            )}
          </SC.CompanyDetails>
        );
    }
  }

  return (
    <Layout>
      {deleteModalOpen && (
        <PromptModal
          variant="danger"
          title={t('company.deleteCompanyTitle')}
          questionKey="company.deleteCompanyQuestion"
          inputError={t('company.deleteCompanyInputError')}
          inputTitle={t('company.deleteCompanyInputTitle')}
          inputPlaceholder={t('company.deleteCompanyInputPlaceholder')}
          name={company.name}
          cancelText={t('company.deleteCompanyCancel')}
          submitText={t('company.deleteCompanySubmit')}
          onClose={handleCloseDeleteModal}
          onSubmit={handleDeleteCompany}
        />
      )}

      {deleteUserModalOpen && (
        <PromptModal
          variant="danger"
          title={t('users.deleteUserTitle')}
          questionKey="users.deleteUserQuestion"
          name={editUser.displayName}
          cancelText={t('users.deleteUserCancel')}
          submitText={t('users.deleteUserSubmit')}
          onClose={handleCloseUserDelete}
          onSubmit={handleDeleteUser}
        />
      )}

      <PageContent title={t('company.settingsTitle')}>
        <SC.Wrapper>
          <Tabs tabs={tabs} activeTab={tab || 'undefined'} />
          {renderContent()}
        </SC.Wrapper>
      </PageContent>
    </Layout>
  );
}
