import { CSSProperties, ReactNode } from 'react';
import * as SC from './styles';

export type TCardPadding = 'small' | 'medium' | 'large';

export type TCardRadius = 'small' | 'medium' | 'large';

interface CardProps {
  children: ReactNode;
  noPadding?: boolean;
  isFullHeight?: boolean;
  padding?: TCardPadding;
  radius?: TCardRadius;
  style?: CSSProperties;
}

export default function Card({ children, noPadding = false, isFullHeight = false, padding = 'large', radius = 'small', style }: CardProps) {
  return (
    <SC.Card $noPadding={noPadding} $isFullHeight={isFullHeight} $padding={padding} $radius={radius} style={style}>
      {children}
    </SC.Card>
  );
}
