import * as SC from './styles';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/Layout/Layout';
import PageContent from '../../components/PageContent/PageContent';
import CopyInput from '../../components/CopyInput/CopyInput';
import { ICompanyState } from '../../redux/company/company.types';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import Card from '../../components/Card/Card';
import { createAircall, getActiveAircall, updateAircall } from '../../redux/integrations/integrations.redux';
import EntityForm from '../../components/EntityForm/EntityForm';
import { IIntegrationsState } from '../../redux/integrations/integrations.types';
import { AircallValidationType, aircallValidation } from '../../schemas';
import { FormField } from '../../common/types';
import Image from '../../components/Image/Image';
import Button from '../../components/Button/Button';

export default function AircallPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id: companyId } = useSelector((state: { company: ICompanyState }) => state.company.company);
  const { activeAircall, isAircallLoading } = useSelector((state: { integrations: IIntegrationsState }) => state.integrations);
  const formKey = JSON.stringify(activeAircall);
  const copyUrl = `${process.env.REACT_APP_BACKEND_URL}/call/${companyId}/aircall-new-call`;
  const GetUserCopyUrl = `${process.env.REACT_APP_BACKEND_URL}/call/${companyId}/get-user-id?phone_number={{callerNumber}}`;

  const aircallFields: FormField[] = [
    {
      name: 'apiId',
      defaultValue: activeAircall?.apiId || '',
      label: t('aircall.formApiId'),
      placeholder: t('aircall.formApiIdPlaceholder'),
      required: true,
      type: 'password',
    },
    {
      name: 'apiToken',
      defaultValue: activeAircall?.apiToken || '',
      label: t('aircall.formApiToken'),
      placeholder: t('aircall.formApiTokenPlaceholder'),
      required: true,
      type: 'password',
    },
  ];

  function handleSave(data: AircallValidationType) {
    if (activeAircall?.id) {
      dispatch(updateAircall({ ...data, id: activeAircall?.id }));
    } else {
      dispatch(createAircall(data));
    }
  }

  function handleOpenRingToApiGuide() {
    window.open('/images/ring-to-api-guid.png', '_blank');
  }

  useEffect(() => {
    if (!companyId) return;
    dispatch(getActiveAircall(companyId));
  }, [companyId]);

  return (
    <Layout>
      <PageContent hasBack title={t('aircall.title')}>
        <SC.Wrapper>
          <Card>
            <CopyInput title={t('aircall.copyLinkTitle')} text={copyUrl} />
          </Card>
          <Card>
            <CopyInput title={t('aircall.copyGetUserLinkTitle')} text={GetUserCopyUrl} />
            <br />
            <Button onClick={handleOpenRingToApiGuide}>{t('aircall.getUserLinkButton')}</Button>
          </Card>
          <Card>
            <EntityForm
              id={String(activeAircall?.id)}
              key={formKey}
              fields={aircallFields}
              onSubmit={handleSave}
              validationSchema={aircallValidation}
              isLoading={isAircallLoading}
            />
          </Card>
        </SC.Wrapper>
      </PageContent>
    </Layout>
  );
}
