import { Tooltip, message } from 'antd';
import Text from 'antd/es/typography/Text';
import { copyItem } from '../../common';

interface ITableEmailsProps {
  value?: string | number | null;
}

export default function CopyItem({ value }: ITableEmailsProps) {
  function handleClick(e: React.MouseEvent, value: string | number) {
    e.stopPropagation();
    copyItem(value.toString());
    message.info('Copied');
  }

  return (
    <Tooltip title="Copy">
      <Text onClick={e => handleClick(e, value || '')} style={{ cursor: 'pointer' }}>
        {value}
      </Text>
    </Tooltip>
  );
}
