import { createGlobalStyle, css, styled } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  :root {
    --white: #ffffff;
    --primary-50: #EBF5FF;
    --primary-100: #E1EFFE;
    --primary-500: #3f83f8;
    --gray-50: #f9fafb;
    --gray-100: #f3f4f6;
    --gray-200: #e5e7eb;
    --gray-300: #d1d5db;
    --gray-400: #9ca3af;
    --gray-500: #6b7280;
    --gray-600: #4b5563;
    --gray-700: #374151;
    --gray-800: #1F2A37;
    --gray-900: #111928;
    --green-500: #0e9f6e;
    --red-500: #f05252;
    --light-gradient: linear-gradient(135.69deg, rgba(67, 165, 255, 0.46) 0.59%, rgba(152, 224, 255, 0.46) 99.41%, rgba(152, 218, 248, 0.46) 99.41%);
    --light-gradient-100: linear-gradient(135.69deg, rgba(105, 138, 255, 0.2) 0.59%, rgba(155, 219, 255, 0.2) 99.41%);
    --dark-gradient: linear-gradient(135.69deg, #7CBFFE 0.59%, #677FFF 99.41%); backdrop-filter: blur(20px);
    --shadow-sm: 0px 1px 2px rgba(0, 0, 0, 0.08);
    --toastify-color-success: ${props => props.theme.colors.green500};
    --toastify-color-error: ${props => props.theme.colors.red500};
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html{
    font-size: 10px;
  }

  body {
    margin: 0;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
      Helvetica Neue, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.5;
    color: ${props => props.theme.colors.gray900};
  }

  button,input,textarea{
    font-family: inherit;
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  /* For Webkit-based browsers (Chrome, Safari and Opera) */
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }

  /* For IE, Edge and Firefox */
  .scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .App {
    text-align: center;
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  #error-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .Toastify{
    position: relative;
    z-index: 10000;
  }

  .Toastify__toast {
    padding: 1.1rem 1.8rem;

  }

  .Toastify__toast-body {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .ant-table-body {
    scrollbar-width: thin;
    scrollbar-color: #eaeaea transparent;
    scrollbar-gutter: stable;
  }
`;

export const HideScrollbar = css`
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const WithCustomScrollbar = css`
  padding-right: 0.5rem;
  &::-webkit-scrollbar {
    width: 0.4rem;
    border-radius: 1.2rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 1.2rem;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.gray300};
    border-radius: 1.2rem;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.colors.gray200};
  }
  @media (max-width: 1023px) {
    padding-left: 0.5rem;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
