import React, { useRef, useState } from 'react';
import * as SC from './styles';
import Icon, { IconName } from '../Icon/Icon';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { useColors } from '../../hooks/theme';
import { Typography } from '../Typography/Typography';
import { IQATagColor } from '../../redux/qa/qa.types';

export interface ISelectOption<T> {
  label: string;
  icon?: IconName;
  value: T;
}

interface ISelectProps<T, U = string> {
  label?: string;
  name: string;
  value: T;
  options: ISelectOption<T>[];
  position?: 'left' | 'right';
  hideChevron?: boolean;
  beforeIcon?: IconName;
  iconColor?: string;
  onChange: (name: U, value: T) => void;
  expand?: boolean;
  tagColors?: IQATagColor[];
}

export default function Select<T, U>({
  label,
  name,
  value,
  options,
  position = 'left',
  hideChevron = false,
  beforeIcon,
  iconColor,
  onChange,
  expand = false,
  tagColors,
}: ISelectProps<T, U>) {
  const [isOpen, setIsOpen] = useState(false);
  const selectedValue = options.find(option => option.value === value);
  const selectRef = useRef<HTMLDivElement>(null);
  const colors = useColors();

  useOnClickOutside(selectRef, () => setIsOpen(false));

  function handleToggle() {
    setIsOpen(prev => !prev);
  }

  function handleChange(value: T) {
    onChange(name as U, value);
    setIsOpen(false);
  }

  function getSelectOption(option: ISelectOption<T>) {
    const optionValue = option.value as any;
    const isActive = JSON.stringify(selectedValue?.value) === JSON.stringify(option.value);

    const hasColor =
      typeof optionValue === 'object' &&
      optionValue !== null &&
      'colorId' in optionValue &&
      tagColors &&
      !!tagColors.find(color => color.id === optionValue.colorId);

    const textColor = hasColor ? tagColors?.find(color => color.id === optionValue.colorId)?.color : undefined;

    return (
      <SC.SelectOption key={option.label} title={option.label} $isActive={isActive} onClick={() => handleChange(option.value)}>
        {option?.icon && <Icon name={option.icon} />}
        <SC.SelectOptionSpan $textColor={textColor}>{option.label}</SC.SelectOptionSpan>
      </SC.SelectOption>
    );
  }

  return (
    <SC.Select color={colors.gray600} ref={selectRef}>
      {!!label && <Typography variant="label">{label}</Typography>}
      <SC.SelectValue title={selectedValue?.label} onClick={handleToggle} $expand={expand}>
        {selectedValue?.icon && <Icon name={selectedValue.icon} color={iconColor} />}
        {beforeIcon && <Icon name={beforeIcon} color={iconColor} />}
        {selectedValue?.label && (
          <SC.SelectValueLabel>
            <Typography variant="label" ellipsis>
              {selectedValue.label}
            </Typography>
          </SC.SelectValueLabel>
        )}
        {!hideChevron && (
          <SC.SelectValueChevron $isActive={isOpen}>
            <Icon name="chevron-down" />
          </SC.SelectValueChevron>
        )}
      </SC.SelectValue>
      <SC.SelectOptions $isOpen={isOpen} $position={position}>
        {options.map(option => getSelectOption(option))}
      </SC.SelectOptions>
    </SC.Select>
  );
}
