import { Select } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2.4rem;
`;

export const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
`;

export const SelectWrapper = styled.div`
  width: 100%;
  @media (max-width: 1440px) {
    width: 20rem;
  }
`;
