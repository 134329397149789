import * as SC from './styles';

// use names from figma for not repeat icons
export type IconName =
  | 'adjustments'
  | 'chat-alt-2'
  | 'users'
  | 'user-circle'
  | 'user-group'
  | 'cog'
  | 'logout'
  | 'chevron-right'
  | 'search'
  | 'user'
  | 'bell'
  | 'chevron-down'
  | 'x'
  | 'plus'
  | 'eye'
  | 'eye-slash'
  | 'chevron-left'
  | 'calculator'
  | 'upload'
  | 'trash'
  | 'pencil-alt'
  | 'globe-alt'
  | 'clipboard-list'
  | 'configuration'
  | 'routing-2'
  | 'calendar'
  | 'menu-alt-1'
  | 'check-circle'
  | 'text'
  | 'number'
  | 'cube'
  | 'companies'
  | 'map-pin'
  | 'check'
  | 'danger'
  | 'drag'
  | 'document-duplicate'
  | 'document-duplicate-2'
  | 'document-text'
  | 'cloud-upload'
  | 'x-circle'
  | 'phone'
  | 'mail'
  | 'theme'
  | 'picker'
  | 'qa'
  | 'help-circle'
  | 'dots-horizontal'
  | 'clock'
  | 'rounded-triangle'
  | 'office-building'
  | 'moon'
  | 'clipboard-text'
  | 'messages'
  | 'camera'
  | 'edit-2'
  | 'message'
  | 'message-question'
  | 'new-tab'
  | 'filter'
  | 'sort'
  | 'sort-up'
  | 'sort-down'
  | 'profile-2user'
  | 'info-circle'
  | 'download'
  | 'chart-bar'
  | 'home-2'
  | 'send'
  | 'send2'
  | 'loading'
  | 'integrations';

interface IconProps {
  name: IconName;
  color?: string;
  width?: number;
  height?: number;
  backgroundWidth?: number;
  backgroundHeight?: number;
  withBackground?: boolean;
  backgroundColor?: string;
}

function Icon({
  name,
  color,
  width = 1.8,
  height = 1.8,
  backgroundWidth = 3.8,
  backgroundHeight = 3.8,
  withBackground = false,
  backgroundColor,
  ...props
}: IconProps) {
  if (withBackground) {
    return (
      <SC.IconBackground color={backgroundColor} $width={backgroundWidth} $height={backgroundHeight} {...props}>
        <SC.Icon src={`/icons/${name}.svg`} $width={width} $height={height} $color={color} />
      </SC.IconBackground>
    );
  }
  return <SC.Icon {...props} src={`/icons/${name}.svg`} $width={width} $height={height} $color={color} />;
}

export default Icon;
