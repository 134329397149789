export const ADDRESS_THROTTLE_TIME = 500;
export const QA_PAGE_SIZE = 10;
export const TYPEFORM_URL = `https://qxr2w9bq569.typeform.com/to/${process.env.REACT_APP_TYPEFORM_FORM_ID}`;
export const SMARTMOVING_STATUSES = [
  { name: 'NewLead', value: 0 },
  { name: 'LeadInProgress', value: 1 },
  { name: 'Opportunity', value: 3 },
  { name: 'Booked', value: 4 },
  { name: 'Completed', value: 10 },
  { name: 'Closed', value: 11 },
  { name: 'Cancelled', value: 20 },
  { name: 'Lost', value: 30 },
  { name: 'BadLead', value: 50 },
];
