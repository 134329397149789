import React from 'react';
import * as SC from './styles';
import Icon, { IconName } from '../Icon/Icon';
import { useColors } from '../../hooks/theme';
import { Typography } from '../Typography/Typography';

export type InfoBoxLevelType = 'info' | 'warning' | 'error' | 'success';

export interface IInfoBoxProps {
  level?: InfoBoxLevelType;
  message: string;
}

export default function InfoBox({ level = 'info', message }: IInfoBoxProps) {
  const colors = useColors();
  const levelColors = {
    info: colors.brand.default,
    warning: colors.warning.default,
    error: colors.error.default,
    success: colors.success.default,
  };
  const levelBackgroundColors = {
    info: colors.brand.lightness,
    warning: colors.warning.lightness,
    error: colors.error.lightness,
    success: colors.success.lightness,
  };
  const levelIcons = { info: 'danger', warning: 'danger', error: 'danger', success: 'check' };
  const messageColor = levelColors[level];
  const backgroundColor = levelBackgroundColors[level];
  const levelIcon = levelIcons[level] as IconName;
  const textColor = level === 'info' ? 'brand' : level;

  return (
    <SC.Info $backgroundColor={backgroundColor}>
      <Icon name={levelIcon} color={messageColor} />
      <Typography variant="subtitle1" color={textColor}>
        {message}
      </Typography>
    </SC.Info>
  );
}
